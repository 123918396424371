import React from 'react'
import familyBusinessImg from '../media/o_nama.jpg'

const AboutUs = () => {
	var background = {
		backgroundImage: `url(${familyBusinessImg})`,
	}

	return (
		<section className="section" id="o-nama">
			<div className="section-text">
				<div>
					<h2>O nama</h2>
					<p>
						Elkopromet je servis osnovan 2017. godine sa ciljem
						pružanja usluge popravke širokog asortimana proizvoda.
						Naša brzina i povoljno pružanje usluge čini nas
						jedinstvenim.
					</p>
				</div>
			</div>
			<div className="section-img" style={background}></div>
		</section>
	)
}

export default AboutUs
