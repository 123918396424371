import React, { useEffect } from 'react'
import smallEngineImg from '../media/small_engine_hd.jpg'

const Home = () => {
	useEffect(() => {
		const downIndicator = document.getElementById('downIndicator')
		const kakoDoNas = document.getElementById('kontakt')

		downIndicator.onclick = () => {
			kakoDoNas.scrollIntoView(true)
		}
	})

	var background = {
		backgroundImage: `url(${smallEngineImg})`,
	}

	return (
		<section className="homeSection" style={background}>
			<div className="homeBanner">
				<h1>ELKOPROMET</h1>
				<p>Najbolji servis malih motora i baštenske opreme u gradu.</p>
			</div>

			<button className="go-to-contact" id="downIndicator">
				KAKO DO NAS
			</button>
		</section>
	)
}

export default Home
