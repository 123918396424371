import './style/style.css'

import AboutUs from './components/AboutUs'
import Home from './components/Home'
import WhatWeDo from './components/WhatWeDo'
import Contact from './components/Contact'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import NasiSaradnici from './components/NasiSaradnici'

function App() {
	return (
		<div className="App">
			<Navigation />
			<Home />
			<AboutUs />
			<WhatWeDo />
			<NasiSaradnici />
			<Contact />
			<Footer />
			<ScrollToTop />
		</div>
	)
}

export default App
