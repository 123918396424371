import React, { useState, useEffect } from 'react'

const ScrollToTop = () => {
	const [scrollValue, setScrollValue] = useState(0)

	const handleScroll = () => {
		const newScroll = window.pageYOffset
		setScrollValue(newScroll)

		const scrollToTopBtn = document.getElementById('scrollToTop')
		if (scrollValue > 300) {
			scrollToTopBtn.style.display = 'initial'
		} else {
			scrollToTopBtn.style.display = 'none'
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })
	}, [scrollValue])

	const goToTop = () => {
		window.scrollTo(0, 0)
	}

	return (
		<button id="scrollToTop" className="scroll-to-top" onClick={goToTop}>
			<span className="material-icons">expand_less</span>
		</button>
	)
}

export default ScrollToTop
