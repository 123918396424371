import React from 'react'

import whatWeDoImg from '../media/cime_se_bavimo.jpg'
var background = {
	backgroundImage: `url(${whatWeDoImg})`,
}

const WhatWeDo = () => {
	return (
		<section className="section change-order" id="cime-se-bavimo">
			<div className="section-img" style={background}></div>
			<div className="section-text">
				<div>
					<h2>Čime se bavimo</h2>
					<p>
						Elkopromet se bavi svakom vrstom servisa i opravke
						kućnih, baštenskih i šumskih alata. Naša ekspertiza su
						uređaju poput kosačica, trimera, motornih testera,
						kompresora, pumpi za vodu, ručnog električnog alata...
						Elkopromet se takođe bavi nabavkom svih vrsta rezervnih
						delova.
					</p>
				</div>
			</div>
		</section>
	)
}

export default WhatWeDo
