import React from 'react'

const NavToggle = ({ icon }) => {
	return (
		<span className="material-icons" id="nav-toggle">
			{icon}
		</span>
	)
}

export default NavToggle
