import React from 'react'

const Contact = () => {
	return (
		<section className="section" id="kontakt">
			<div className="section-text">
				<div>
					<h2>Kako do nas</h2>
					<ul>
						<li>
							<span className="material-icons icon-align">
								call
							</span>{' '}
							+381 69 680 095
						</li>
						<li>
							<span className="material-icons icon-align">
								email
							</span>{' '}
							elkopromet@gmail.com
						</li>
						<li>
							<span className="material-icons icon-align">
								place
							</span>{' '}
							Marko Peričin Kamenjar 14a, Grgurevci
						</li>
						<li>
							<span className="material-icons icon-align">
								date_range
							</span>{' '}
							PON - PET : 08:00 - 16:00
						</li>
						<li>
							<span className="material-icons icon-align">
								date_range
							</span>{' '}
							SUBOTA : 08:00 - 14:00
						</li>
					</ul>
				</div>
			</div>
			<div className="section-img">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d997.9637630882996!2d19.625596266940462!3d44.970744042771706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475ba554d286e0d3%3A0xc5aee3821f72b0cd!2sElkopromet!5e0!3m2!1sen!2srs!4v1623668390619!5m2!1sen!2srs"
					style={{ border: 0 }}
					allowFullScreen=""
					loading="lazy"
				></iframe>
			</div>
		</section>
	)
}

export default Contact
