import React, { useState, useEffect } from 'react'
import NavToggle from './NavToggle'

const Navigation = () => {
	const [toggleIcon, setToggleIcon] = useState('menu')

	useEffect(() => {
		const navToggle = document.getElementById('nav-toggle')
		const sideNav = document.getElementById('side-nav')

		// otvara i zatvara meni sa strane
		navToggle.onclick = () => {
			sideNav.classList.toggle('prikazi')

			// postavlja odgovarajucu ikonicu u span
			if (sideNav.classList.contains('prikazi')) {
				setToggleIcon('close')
			} else {
				setToggleIcon('menu')
			}
		}
	})

	return (
		<div className="navbar">
			<div>logo</div>
			<div className="d-nav">
				<nav>
					<a href="#o-nama">O nama</a>
					<a href="#cime-se-bavimo">Čime se bavimo</a>
					<a href="#nasi-saradnici">Naši saradnici</a>
					<a href="#kontakt">Kako do nas</a>
				</nav>
				<NavToggle icon={toggleIcon} />

				{/* navigacija na malim ekranima */}
				<div className="side-nav" id="side-nav">
					<nav>
						<a href="#o-nama">O nama</a>
						<a href="#cime-se-bavimo">Čime se bavimo</a>
						<a href="#nasi-saradnici">Naši saradnici</a>
						<a href="#kontakt">Kako do nas</a>
					</nav>
				</div>
			</div>
		</div>
	)
}

export default Navigation
