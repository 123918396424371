import React from 'react'

const Footer = () => {
	return (
		<footer>
			<p>Copyright 2021 © Elkopromet d.o.o.</p>
			<p>Made by Srđan Sanadrović</p>
		</footer>
	)
}

export default Footer
