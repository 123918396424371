import React from 'react'
import villagerLogo from '../media/saradnici_logo/villager.png'
import stihlLogo from '../media/saradnici_logo/stihl.png'
import boschLogo from '../media/saradnici_logo/bosch.png'
import vikingLogo from '../media/saradnici_logo/viking.png'
import husqvarnaLogo from '../media/saradnici_logo/husqvarna.png'
import makitaLogo from '../media/saradnici_logo/makita.png'
import hondaLogo from '../media/saradnici_logo/honda.png'
import REMLogo from '../media/saradnici_logo/rem.png'

const NasiSaradnici = () => {
	return (
		<div id="nasi-saradnici" className="saradnici">
			<h2>Nasi saradnici</h2>
			<p>
				Elkopromet saradjuje i pruza usluge servisa proizvoda mnogih
				kompanija. Neki od nasih najvecih saradnika su:
			</p>

			<div className="saradnici-logo-container">
				<div>
					<img src={villagerLogo} alt="" />
				</div>
				<div>
					<img src={stihlLogo} alt="" />
				</div>
				<div>
					<img src={boschLogo} alt="" />
				</div>
				<div>
					<img src={vikingLogo} alt="" />
				</div>
				<div>
					<img src={husqvarnaLogo} alt="" />
				</div>
				<div>
					<img src={makitaLogo} alt="" />
				</div>
				<div>
					<img src={hondaLogo} alt="" />
				</div>
				<div>
					<img src={REMLogo} alt="" />
				</div>
			</div>
		</div>
	)
}

export default NasiSaradnici
